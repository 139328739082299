<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <!-- <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3F4254">Quản lí danh mục FAQ</h1>
    </div> -->
    <!-- <div class="pt-5 pl-5 pr-5"> -->
    <div class="">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogFaq"
        v-if="canAccess('faq-view')"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm danh mục</span>
      </v-btn>
      <v-btn
        rounded
        color="cyan"
        class="mb-5 ml-5"
        x-small
        large
        @click="openDialogSort"
        v-if="canAccess('faq-update')"
      >
        <v-icon color="white" dark>mdi-sort</v-icon
        ><span style="color: white">Sắp xếp lại danh mục</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Title</th>
                    <th scope="col">FAQ</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(category, i) in faq_categories">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-html="category.title"
                        ></p>
                      </td>
                      <td>
                        <p class="mb-0">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Tổng số câu hỏi:
                          </span>
                          <span
                            class="text-dark-75 blue-grey--text text--lighten-1 font-size-lg"
                            >{{ category.total_question | formatNumber }}</span
                          >
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'FaqQuestion',
                                  params: { faq_category_id: category.id },
                                }"
                                target="_blank"
                              >
                                <button
                                  class="btn btn-icon btn-light-success btn-sm"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color=""
                                      >mdi-frequently-asked-questions</v-icon
                                    >
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>FAQ Question</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-primary btn-sm ml-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="editCategory(i)"
                                v-if="canAccess('faq-update')"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="btnDeleteCategory(category.id)"
                                v-if="canAccess('faq-delete')"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog v-model="diaLogFaq" max-width="1000px" scrollable persistent>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span v-if="type_form === 'add'" class="headline"
                >Thêm danh mục</span
              >
              <span v-if="type_form === 'update'" class="headline"
                >Sửa danh mục</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="diaLogFaq = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <label style="font-size: 16px">Nhập tên danh mục</label>
                  <v-text-field filled v-model="title_input"></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <label style="font-size: 16px">Product category</label>
                  <div>
                    <div class="d-flex">
                      <v-autocomplete
                        v-model="product_category_selected"
                        :items="product_category_unselect"
                        item-value="id"
                        item-text="name.vi"
                        filled
                        multiple
                        chips
                      >
                      </v-autocomplete>
                      <v-btn
                        color="cyan"
                        class="ma-2 mt-2 font-weight-bold"
                        outlined
                        small
                        @click="saveProductCategory()"
                      >
                        <v-icon small left>mdi-check</v-icon>
                        Thêm
                      </v-btn>
                    </div>
                    <p v-if="product_category_input.length">
                      (Hãy di chuyển button nếu muốn sắp xếp lại)
                    </p>
                    <div
                      style="background-color: #f2f2f2; width: 100%"
                      class="pt-2 pb-2"
                    >
                      <p
                        v-if="product_category_input.length === 0"
                        class="text-center"
                      >
                        no data
                      </p>
                      <template v-if="product_category_input.length">
                        <draggable :list="product_category_input">
                          <template v-for="(item, i) in product_category_input">
                            <v-btn
                              style="background-color: #3949ab; color: white"
                              dense
                              class="ml-2 mt-1 mb-1"
                              :key="i"
                            >
                              {{ i + 1 }} - {{ item.name.vi }}
                              <v-icon
                                dense
                                color="white"
                                class="ml-1"
                                dark
                                @click="delProductCategory(i)"
                                >mdi-close</v-icon
                              >
                            </v-btn>
                          </template>
                        </draggable>
                      </template>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEdit(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEdit(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="diaLogFaq = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogSort" scrollable persistent max-width="600px">
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sắp xếp lại danh mục</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSort = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled drag-css"
                      v-for="(category, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h5 class="mt-0 mb-1" style="color: #3699ff">
                          {{ category.title }}
                        </h5>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" outlined text @click="sortFaqCategory"
              >Update</v-btn
            >
            <v-btn color="red darken-1" text @click="dialogSort = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
import draggable from "vuedraggable";

export default {
  name: "FaqCategory",
  components: {
    draggable,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      faq_categories: [],
      type_form: null,
      diaLogFaq: false,
      title_input: "",
      faq_category_id: null,
      data_draggable: [],
      dialogSort: false,
      product_category_tags: [],
      product_category_unselect: [],
      product_category_selected: [],
      product_category_input: [],
    };
  },
  async created() {
    this.getAllProductCategory();
    this.is_call_api = true;
    await this.getAllFaqCategory();
    this.is_call_api = false;
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    async getAllProductCategory() {
      try {
        let res = await ApiService.get("prep-app/product-category/limit");
        if (res.status === 200) {
          this.product_category_tags = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllFaqCategory() {
      let vm = this;
      try {
        let res = await ApiService.get("prep-app/faq-category");
        if (res.status === 200) {
          vm.faq_categories = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    openDiaLogFaq() {
      this.type_form = "add";
      this.title_input = "";
      this.product_category_unselect = JSON.parse(
        JSON.stringify(this.product_category_tags)
      );
      this.product_category_input = [];
      this.diaLogFaq = true;
    },
    editCategory(index) {
      let data = this.faq_categories[index];
      this.title_input = data.title;
      this.faq_category_id = data.id;
      this.product_category_unselect = JSON.parse(
        JSON.stringify(this.product_category_tags)
      );
      this.product_category_input = data.product_categories;
      this.type_form = "update";
      this.diaLogFaq = true;
    },
    btnAddOrEdit(type) {
      let vm = this;
      let data = {
        title: this.title_input,
        product_category_ids: this.product_category_input.map((e) => e.id),
      };
      vm.is_call_api = true;
      if (type === 1) {
        data.offset = this.faq_categories.length;
        ApiService.post("prep-app/faq-category", data)
          .then(function (res) {
            if (res.status === 200) {
              vm.is_call_api = false;
              vm.getAllFaqCategory();
              vm.diaLogFaq = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        ApiService.put("prep-app/faq-category/" + this.faq_category_id, data)
          .then(function (res) {
            if (res.status === 200) {
              vm.is_call_api = false;
              vm.getAllFaqCategory();
              vm.diaLogFaq = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    btnDeleteCategory(faq_category_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.is_call_api = true;
          ApiService.delete("prep-app/faq-category/" + faq_category_id)
            .then(function (res) {
              if (res.status === 200) {
                vm.$toasted.success("Delete successfully!!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.getAllFaqCategory();
                vm.is_call_api = false;
              }
            })
            .catch(function (error) {
              console.log(error);
              vm.is_call_api = false;
            });
        }
      });
    },
    openDialogSort() {
      this.data_draggable = JSON.parse(JSON.stringify(this.faq_categories));
      this.dialogSort = true;
    },
    sortFaqCategory() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          let items = this.data_draggable.map((item, index) => {
            return {
              faq_category_id: item.id,
              offset: index + 1,
            };
          });
          let data = {
            data_sort: items,
          };
          vm.is_call_api = true;
          ApiService.post("prep-app/faq-category/sort", data)
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllFaqCategory();
                Swal.fire({
                  title: "",
                  text: "The application has been successfully submitted!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
                vm.is_call_api = false;
                vm.dialogSort = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
    saveProductCategory() {
      for (let i = 0; i < this.product_category_selected.length; i++) {
        let obj_product_category = this.product_category_unselect.filter(
          (e) => e.id === this.product_category_selected[i]
        )[0];
        if (
          this.product_category_input.some(
            (ele) => ele.id === this.product_category_selected[i]
          )
        ) {
          this.$toasted.info(
            "Sản phẩm " +
              obj_product_category.name.vi +
              " đã được thêm trước đó",
            { theme: "toasted-primary", position: "top-right", duration: 4000 }
          );
        } else {
          this.product_category_input.push(obj_product_category);
        }
        // this.product_category_input.push(this.product_category_unselect.filter(e => e.id === this.product_category_selected[i])[0]);
      }
      this.product_category_selected = [];
    },
    delProductCategory(index) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.product_category_input.splice(index, 1);
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
